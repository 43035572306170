import React from 'react';
import './header.css';
import ai from '../../assets/aia.gif';
import  Feature  from '../../components/feature/Feature';
import '../../containers/whatGPT3/whatGPT3.css';

const Header = () => {
    return(
    <div>


        <div className="gpt3__header section_padding" id="home"> 
            <div className="gpt3__header-image">
                <img src={ai} alt="aia" />
            </div> 
        </div>


        <div className="gpt3__whatgpt3 section__margin" id="home">
            <div className="gpt3__whatgpt3-container">
                <Feature title="LayeredHeads NFT Governance" text="LayeredHeads is a platform that offers ownership of 9,999 unique ERC-721 tokens stored on the Ethereum Blockchain.
Each individual character encapsulates a myriad of human knowledge and encounters inherent to the realm of web3." />
                <Feature title="$LAID Utility Token" text="Token holders are afforded the opportunity to stake their PFP NFT, Thereby procuring $LAID. Those who opt to partake in this endeavor shall be the recipients of a generous $LAID airdrop." />
            </div>  
        </div>


    </div>


    )
}

export default Header;