import React from 'react';
import Feature from '../../components/feature/Feature';
import './features.css';

const featuresData = [
  {
    title: 'LayeredHeadsNFT - Genesis NFT',
    text: '9,999 LayeredHeads grinding through the layers of web3 and onto the blockchain. ' + 
    'Every character in this collection represents the experiences and knowledge of humans in web3. ',
  },

  {
    title: 'Staking Daap  -  Get $LAID',
    text: 'Stake your LayeredHeadNFT and get $LAID tokens.  ',
  },
 
  {
    title: 'Token Launch  -  Grab your $LAID',
    text: '$LAID officially takes off.   ' + 
    'Secure your spot in the meme revolution early.',
  },
 
  
  
];

const Features = () => (
  <div className="gpt3__features section__padding" id="features">
    <div className="gpt3__features-heading">
      <h1 className="gradient__text">Roadmap</h1>
     
    </div>
    <div className="gpt3__features-container">
      {featuresData.map((item, index) => (
        <Feature title={item.title} text={item.text} key={item.title + index} />
      ))}
    </div>
  </div>
);

export default Features;
