import React, { useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import logo from '../../assets/atlassian.png';
import './navbar.css';

const Menu = () => (
  <>
  <p><a href="#home">The Project</a></p>
  <p><a href="#wgpt3">Vision & Commitment</a></p>
 <p><a href="#features">Roadmap</a></p>
 <p><a href="#possibility">Advantages</a></p>



 
 <p><a href="https://LayeredHeadsnft.com/">Mint LayeredHeadsNFT</a></p>

  
  </>
)
const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);
  return (
    <div className="gpt3__navbar">
      
      <div className="gpt3__navbar-links">
        <div className="gpt3__navbar-links_logo">
          {/* <img src={logo} alt="logo" /> */}
        </div>
        <div className="gpt3__navbar-links_container">
          <Menu />
        </div>
      </div>

    


      <div className="gpt3__navbar-menu">


      {toggleMenu
          ? <RiCloseLine color="#fff" size={27} onClick={() => setToggleMenu(false)} />
          : <RiMenu3Line color="#fff" size={27} onClick={() => setToggleMenu(true)} />
        }
        {toggleMenu && (
          <div className="gpt3__navbar-menu_container scale-up-center">
            <div className="gpt3__navbar-menu_container-links">
            <Menu />

           

      
            </div>
          </div>
        )}


      </div>







    </div>
  );
}



export default Navbar;
