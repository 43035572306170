import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import pointingImage from '../../assets/pointing.png';
import './possibility.css';

const Possibility = () => (
  <div className="gpt3__possibility section__padding" id="possibility">
    <div className="gpt3__possibility-image">
      <img src={possibilityImage} alt="possibility" />
    </div>
    <div className="gpt3__possibility-content">
      <h4>Membership Advantages </h4>
      
     
      <p className="gpt3_possibility__text_padding"> Limited Edition NFT collection</p>
      <p className="gpt3_possibility__text_padding"> NFT Staking Dap</p>
      <p className="gpt3_possibility__text_padding"> Access to $LAID presale</p>
      <p className="gpt3_possibility__text_padding"> Access to $LAID airdrop</p>
      <p className="gpt3_possibility__text_padding"> Tokenomics and Merch</p>
      <p className="gpt3_possibility__text_padding"> NFT ArtIP Rights</p>
      <p className="gpt3_possibility__text_padding"> Early Access to other Projects</p>
      <p className="gpt3_possibility__text_padding"> Access to Exclusive Events</p>


   
        <p></p>
      <h4>Embark on a journey through the captivating assemblage that is LayeredHeads. Dabble in interactions with fellow connoisseurs, partake in exclusive IRL events, and unlock a trove of supplemetary advantages nested within the LayeredHeads ecosystem.</h4>
    </div>
  </div>
);

export default Possibility;

