import React from 'react';
import  Feature  from '../../components/feature/Feature';
import './whatGPT3.css';

const WhatGPT3 = () => (
  <div className="gpt3__whatgpt3 section__margin" id="wgpt3">
     

    <div className="gpt3__whatgpt3-container">

     

 


 
{/* Explore the mesmerizing collection of LayeredHeads and choose your favorites.
 Engage with fellow collectors, participate in exclusive events, and unlock additional perks within the @Layered Heads ecosystem.
 */}



      <Feature title="LayeredHeads" text="We bring superior content experince for web3 users, more hospitable/creative infrastructure and better vallue for artists and creators." />
      
      
      <Feature title="Community" text="Our mission is to create a community that cares about issues of web3 sustainability, Freedom, Transparency
Authenticity and Unique Ownership, Prestige, Luxury and High reputation.

" />




      <Feature title="web3" text="A vibrant community of enthusiasts who share a passion for web3 and all of its experiences that come with it such as NFTs, digital art, tokenomics and innovative technologies.
 " />
    </div>
  </div>
);

export default WhatGPT3